/*****************
    - Header -
*****************/

/* @charset "utf-8"; */
/* @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900|Open Sans:400,600,800"); */
h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
p,
a {
  /* font-family: "Open Sans"; */
  margin: 0px;
}

a,
a:hover,
a:focus {
  color: inherit;
}

body {
  background-color: #f1f2f3;
}

.container-fluid,
.container {
  max-width: 1200px;
}

.card-container {
  padding: 100px 0px;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.profile-card-6 {
  border: 0.5px solid black;
  max-width: 400px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  height: 90%;
}

.profile-card-6 img {
  transition: all 0.15s linear;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), black);
  filter: brightness(80%);
  height: 100%;
}

.profile-card-6 .profile-name {
  position: absolute;

  top: 10px;
  left: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  padding: 15px 20px;
  background: linear-gradient(
    140deg,
    rgba(0, 0, 0, 0.4) 70%,
    rgba(255, 255, 0, 0) 70%
  );
  transition: all 0.15s linear;
}

.profile-card-6 .profile-position {
  position: absolute;

  color: rgba(255, 255, 255, 0.4);
  left: 30px;
  top: 100px;
  transition: all 0.15s linear;
}

.profile-card-6 .profile-overview {
  position: absolute;
 
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(255, 255, 0, 0)
  );
  color: #fff;
  padding: 50px 0px 20px 0px;
  transition: all 0.15s linear;
}

.profile-card-6 .profile-overview h3 {
  font-weight: bold;
}

.profile-card-6 .profile-overview p {
  color: rgba(255, 255, 255, 0.7);
}

.profile-card-6:hover img {
  filter: brightness(80%);
}

.profile-card-6:hover .profile-name {
  padding-left: 25px;
  padding-top: 20px;
}

.profile-card-6:hover .profile-position {
  left: 40px;
}

.profile-card-6:hover .profile-overview {
  padding-bottom: 25px;
}
